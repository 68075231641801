body {
  margin: 60px auto;
  font-family: Georgia, Palatino, 'Palatino Linotype', Times, 'Times New Roman', serif;
  background-color: #f6f5ed;
  color: #484848;
  width: 600px;
  font-size: 18px;
  line-height: 26px;
}

@media (max-width: 768px) {
  body {
    width: 90%; /* Adjust the width for small devices */
  }
}


img.avatar {
  background-size: 100%;
  display: block;
  height: 130px;
  overflow: hidden;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  width: 130px;
  margin-right: 15px;
}

img {
  overflow-clip-margin: content-box;
  overflow: clip;
}

header a {
  color: #484848;
  text-decoration: none;
  border-bottom: 1px dotted #999892;
}

article a {
  color: #484848;
  text-decoration: none;
  border-bottom: 1px dotted #999892;
}

header div a {
  border-bottom: 0px;
}

li {
  margin-bottom: 5px;
}
